import ApiService from "@/core/services/ApiService";
import { Provider } from "@/modules/provider/provider.module";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/ReferringProviders";

export async function searchReferringProviders(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
export async function getReferringProviders() {
  return await ApiService.get(url)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addProvider(provider) {
  return await ApiService.post(url, provider as never).then();
}

export async function getProvider(providerId) {
  return await ApiService.get(url + "/" + providerId)
    .then((res) => {
      const provider = res.data;
      return provider;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateProvider(provider) {
  if (provider) {
    await ApiService.put(url + "/" + provider.id, provider as never)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteProvider(provider) {
  if (provider) {
    return await ApiService.delete(url + "/" + provider?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}
